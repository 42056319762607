console.log('VUE_APP_NODE_ENV:', process.env.VUE_APP_NODE_ENV);
console.log('LOCALE:', process.env.VUE_APP_I18N_LOCALE);
console.log('HTTPS:', process.env.HTTPS);
let apiUrl = "https://localhost/api";
switch (process.env.VUE_APP_NODE_ENV) {
	case "development":
		apiUrl = "https://localhost/api";
		break;
	case "aws-development":
		apiUrl = "https://dev.tolspor.is/api";
		break;
	case "aws-production":
		apiUrl = "https://tolspor.is/api";
		break;
	default:
		console.error("VUE_APP_NODE_ENV not set, using developments api urls");
		apiUrl = "https://localhost/api";  // or set to prod or awsDev as per your requirement
		break;
}
export default {
  apiUrl: apiUrl,
  baseUrl: apiUrl.replace('/api',''),
  colors: {
    default: '#344675',
    primary: '#42b883',
    info: '#1d8cf8',
    danger: '#fd5d93',
    orange: '#ff8a76',
    teal: '#00d6b4',
    primaryGradient: [
      'rgba(76, 211, 150, 0.1)',
      'rgba(53, 183, 125, 0)',
      'rgba(119,52,169,0)'
    ],
    purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)']
  }
};
