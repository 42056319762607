import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Calendar
// const Calendar = () =>
//   import(/* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue');
// // Charts
// const Charts = () =>
//   import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

//MyTools
const MyTools = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Tools/MyTools.vue');

//Tools
const Tools = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Tools/Tools.vue');
const Tool = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Tools/Tool.vue');

//Projects
const Projects = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Projects/Projects.vue');
const Project = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Projects/Project.vue');

//Locations
const Locations = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Locations/Locations.vue');

//Users
const Users = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Users/Users.vue');
const User = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Users/User.vue');

//Cars
const Cars = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Cars/Cars.vue');
const Car = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Cars/Car.vue');

//Settings
const AppSettings = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Settings/AppSettings.vue');
const CustomerSettings = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Settings/CustomerSettings.vue');

//WarehouseUnreserve
const WarehouseUnreserve = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/WarehouseUnreserve/WarehouseUnreserve.vue');

// Components pages
// const Buttons = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/Buttons.vue');
// const GridSystem = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/GridSystem.vue');
// const Panels = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/Panels.vue');
// const SweetAlert = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/SweetAlert.vue');
// const Notifications = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/Notifications.vue');
// const Icons = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/Icons.vue');
// const Typography = () =>
//   import(/* webpackChunkName: "components" */ 'src/pages/Components/Typography.vue');

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
import Widgets from 'src/pages/Widgets.vue';

// Forms pages
// const RegularForms = () => import('src/pages/Forms/RegularForms.vue');
// const ExtendedForms = () => import('src/pages/Forms/ExtendedForms.vue');
// const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');
// const Wizard = () => import('src/pages/Forms/Wizard.vue');

// Analytics pages
const Analytics = () => import('src/pages/Analytics/GeneralAnalytics.vue');

// Confirm Registration
const ConfirmRegistration = () => import('src/pages/Registration/ConfirmRegistration.vue');

// Reset Password
const ResetPassword = () => import('src/pages/Registration/ResetPassword.vue');

// Forgot Password
const ForgotPassword = () => import('src/pages/Registration/ForgotPassword.vue');
// Maps pages - for later
// const GoogleMaps = () =>
//   import(/* webpackChunkName: "extra" */ 'src/pages/Maps/GoogleMaps.vue');
// const FullScreenMap = () =>
//   import(/* webpackChunkName: "extra" */ 'src/pages/Maps/FullScreenMap.vue');
// const VectorMaps = () =>
//   import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');

// Pages
const UserProfile = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
// const TimeLine = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/TimeLinePage.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Documentation = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Documentation/Documentation.vue');
// const Register = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
// const RTL = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
// const Lock = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');

// TableList pages
// const RegularTables = () =>
//   import(/* webpackChunkName: "tables" */ 'src/pages/Tables/RegularTables.vue');
// const ExtendedTables = () =>
//   import(/* webpackChunkName: "tables" */ 'src/pages/Tables/ExtendedTables.vue');
// const PaginatedTables = () =>
//   import(/* webpackChunkName: "tables" */ 'src/pages/Tables/PaginatedTables.vue');
// let componentsMenu = {
//   path: '/components',
//   component: DashboardLayout,
//   redirect: '/components/buttons',
//   name: 'Components',
//   children: [
//     {
//       path: 'buttons',
//       name: 'Buttons',
//       components: { default: Buttons },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'grid-system',
//       name: 'Grid System',
//       components: { default: GridSystem },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'panels',
//       name: 'Panels',
//       components: { default: Panels },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'sweet-alert',
//       name: 'Sweet Alert',
//       components: { default: SweetAlert },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'notifications',
//       name: 'Notifications',
//       components: { default: Notifications },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'icons',
//       name: 'Icons',
//       components: { default: Icons },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'typography',
//       name: 'Typography',
//       components: { default: Typography },
//       meta: { requiresAuth: true }  // This route requires authentication
//     }
//   ]
// };
// let formsMenu = {
//   path: '/forms',
//   component: DashboardLayout,
//   redirect: '/forms/regular',
//   name: 'Forms',
//   children: [
//     {
//       path: 'regular',
//       name: 'Regular Forms',
//       components: { default: RegularForms },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'extended',
//       name: 'Extended Forms',
//       components: { default: ExtendedForms },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'validation',
//       name: 'Validation Forms',
//       components: { default: ValidationForms },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'wizard',
//       name: 'Wizard',
//       components: { default: Wizard },
//       meta: { requiresAuth: true }  // This route requires authentication
//     }
//   ]
// };

// let tablesMenu = {
//   path: '/table-list',
//   component: DashboardLayout,
//   redirect: '/table-list/regular',
//   name: 'Tables',
//   children: [
//     {
//       path: 'regular',
//       name: 'Regular Tables',
//       components: { default: RegularTables },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'extended',
//       name: 'Extended Tables',
//       components: { default: ExtendedTables },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'paginated',
//       name: 'Paginated Tables',
//       components: { default: PaginatedTables },
//       meta: { requiresAuth: true }  // This route requires authentication
//     }
//   ]
// };

let settingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/appsettings',
  name: 'Settings',
  children: [
    {
      path: 'appsettings',
      name: 'App Settings',
      components: { default: AppSettings },
      meta: { requiresAuth: true }  // This route requires authentication
    },
    {
      path: 'customersettings',
      name: 'Customer Settings',
      components: { default: CustomerSettings },
      meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
    }
  ]
};

let docsMenu = {
  path: '/docs',
  component: DashboardLayout,
  redirect: '/docs/introduction',
  name: 'Documentation',
  children: [
    {
      path: 'introduction',
      name: 'Documentation',
      components: { default: Documentation },
      meta: { requiresAuth: true }  // This route requires authentication
    },
    {
      path: ':page',
      name: 'Documentation',
      components: { default: Documentation },
      meta: { requiresAuth: true }  // This route requires authentication
    }
  ]
};

// let mapsMenu = {
//   path: '/maps',
//   component: DashboardLayout,
//   name: 'Maps',
//   redirect: '/maps/google',
//   children: [
//     {
//       path: 'google',
//       name: 'Google Maps',
//       components: { default: GoogleMaps },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'full-screen',
//       name: 'Full Screen Map',
//       meta: {
//         hideContent: true,
//         hideFooter: true
//       },
//       components: { default: FullScreenMap },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'vector-map',
//       name: 'Vector Map',
//       components: { default: VectorMaps },
//       meta: { requiresAuth: true }  // This route requires authentication
//     }
//   ]
// };

// let pagesMenu = {
//   path: '/pages',
//   component: DashboardLayout,
//   name: 'Pages',
//   redirect: '/pages/timeline',
//   children: [
//     {
//       path: 'timeline',
//       name: 'Timeline Page',
//       components: { default: TimeLine },
//       meta: { requiresAuth: true }  // This route requires authentication
//     },
//     {
//       path: 'rtl',
//       name: 'RTL Page',
//       components: { default: RTL },
//       meta: { requiresAuth: true }  // This route requires authentication
//     }
//   ]
// };

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: Register
    // },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/confirm-registration',
      name: 'Confirm Registration',
      component: ConfirmRegistration
    },
    {
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword
    },
    // {
    //   path: '/lock',
    //   name: 'Lock',
    //   component: Lock
    // }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/mytools',
    name: 'Home',
    meta: { requiresAuth: true }  // This route requires authentication
  },
  //componentsMenu,
  //formsMenu,
  //tablesMenu,
  //mapsMenu,
  //pagesMenu,
  docsMenu,
  settingsMenu,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/mytools',
    name: 'My Tools layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
      },
      {
        path: 'mytools',
        name: 'Mytools',
        components: { default: MyTools },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: 'tools',
        name: 'Tools',
        components: { default: Tools },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/tool/:toolId',
        name: 'Tool',
        components: { default: Tool },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'projects',
        name: 'Projects',
        components: { default: Projects },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/project/:projectId',
        name: 'Project',
        components: { default: Project },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'cars',
        name: 'Cars',
        components: { default: Cars },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: 'warehouseunreserve',
        name: 'WarehouseUnreserve',
        components: { default: WarehouseUnreserve },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/car/:toolGroupId',
        name: 'Car',
        components: { default: Car },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      {
        path: 'locations',
        name: 'Locations',
        components: { default: Locations },
        meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
      },
      {
        path: 'users',
        name: 'Users',
        components: { default: Users },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: '/user/:userId',
        name: 'User',
        components: { default: User },
        meta: { requiresAuth: true },  // This route requires authentication
        props: true,
      },
      // {
      //   path: 'calendar',
      //   name: 'Calendar',
      //   components: { default: Calendar },
      //   meta: { requiresAuth: true }  // This route requires authentication
      // },
      // {
      //   path: 'charts',
      //   name: 'Charts',
      //   components: { default: Charts },
      //   meta: { requiresAuth: true }  // This route requires authentication
      // },
      // {
      //   path: 'widgets',
      //   name: 'Widgets',
      //   components: { default: Widgets },
      //   meta: { requiresAuth: true }  // This route requires authentication
      // },
      {
        path: 'userprofile/:userId',
        name: 'User Profile Page',
        components: { default: UserProfile },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: 'userprofile',
        name: 'User Profile',
        components: { default: UserProfile },
        meta: { requiresAuth: true }  // This route requires authentication
      },
      {
        path: 'analytics',
        name: 'Analytics',
        components: { default: Analytics },
        meta: { requiresAuth: true, roles: ['Admin', 'SuperAdmin'] }  // This route requires authentication
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
