import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import config from "../config";

const api = axios.create({
  baseURL: config.apiUrl
});

api.interceptors.request.use(async (config) => {
    const token = Cookies.get('accessToken');

    if (token) {
        let decodedToken;
        try {
            decodedToken = jwtDecode(token);
        } catch (error) {
            console.error("Invalid access token:", error);
            return config;
        }

        const expiryDate = new Date(decodedToken.exp * 1000);
        if (expiryDate < new Date()) {
            const refreshToken = Cookies.get('refreshToken');
            try {
                decodedToken = jwtDecode(refreshToken);
            } catch (error) {
                console.error("Invalid refresh token:", error);
                return config;
            }

            const expiryDate = new Date(decodedToken.exp * 1000);
            if (expiryDate > new Date()) {
                try {
                    const response = await axios.post(`${config.apiUrl}/identity/RefreshToken`, {
                        refreshToken: refreshToken
                    });

                    Cookies.set('accessToken', response.data.accessToken, { secure: true, httpOnly: false });
                    Cookies.set('refreshToken', response.data.refreshToken, { secure: true, httpOnly: false });

                    config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                } catch (error) {
                    console.error(error);
                    Cookies.remove('accessToken');
                    Cookies.remove('refreshToken');
                    window.location.href = config.apiUrl + '/login';
                    throw new Error("Unable to refresh token, please try logging in again");
                }
            }
        } else {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;
