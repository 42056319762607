/* eslint-disable no-console */

import { register } from 'register-service-worker'
import swal from 'sweetalert2';
import Cookies from 'js-cookie';

let locale = Cookies.get('locale');
let localeTitle = "New version is available, click OK to update";
let localeButton = "OK"
switch (locale) {
	case "is-IS":
    localeTitle = "Ný útgáfa tilbúinn, ýttu á OK til að endurhlaða síðunni";
    localeButton = "OK"
		break;
	case "pl-PL":
		localeTitle = "Dostępna jest nowa wersja, kliknij OK, aby zaktualizować";
    localeButton = "OK"
		break;
	case "lt-LT":
		localeTitle = "Nauja versija yra prieinama, spustelėkite OK, kad atnaujintumėte";
    localeButton = "OK"
		break;
}

if (process.env.VUE_APP_NODE_ENV === 'aws-production' || process.env.VUE_APP_NODE_ENV === 'aws-development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: process.env.BASE_URL,
    },
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload(true)
      swal.fire({
        title: localeTitle,
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
        },
        confirmButtonText: localeButton,
        buttonsStyling: false
      }).then(result => {
        // Refresh the page
        location.reload();
      });
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});