<template>
  <div class="fileinput text-center">
    <div
      class="fileinput-new thumbnail"
      :class="{ 'img-circle': type === 'avatar' }"
    >
      <img :src="image" alt="preview" />
    </div>
    <div>
      <span v-if="!fileExists" class="btn btn-standard btn-file">
        <span class="fileinput-new">{{ fileExists ? uploadText : selectText }}</span>
        <input
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"
        />
      </span>
      <base-button v-if="fileExists && showUploadButton" @click="uploadFile" round type="standard">
        <i class="fas fa-upload"></i> {{ $t('image.upload') }}
      </base-button>
      <base-button v-if="fileExists" @click="removeFile" round type="warning">
        <i class="fas fa-times"></i> {{ $t('image.remove') }}
      </base-button>
    </div>
  </div>
</template>
<script>
import imageCompression from 'browser-image-compression';

export default {
  name: 'image-upload',
  props: {
    type: {
      type: String,
      default: '',
      description: 'Image upload type (""|avatar)'
    },
    src: {
      type: String,
      default: '',
      description: 'Initial image to display'
    },
    selectText: {
      type: String,
      default: 'Select image'
    },
    uploadText: {
      type: String,
      default: 'Upload'
    },
    removeText: {
      type: String,
      default: 'Remove'
    },
    base64Image: {
      type: String,
      default: '',
      description: 'Base64 string of the image'
    },
    showUploadButton: {
      type: Boolean,
      default: true,
      description: 'Control if upload button should be showed'
    }
  },
  data() {
    let avatarPlaceholder = 'img/placeholder.jpg';
    let imgPlaceholder = 'img/image_placeholder.jpg';
    return {
      placeholder: this.type === 'avatar' ? avatarPlaceholder : imgPlaceholder,
      imagePreview: null
    };
  },
  computed: {
    fileExists() {
      return this.imagePreview !== null;
    },
    image() {
      return this.imagePreview || this.src || this.placeholder;
    }
  },
  methods: {
    async handlePreview(event) {
      let file = event.target.files[0];
      // Compress the image
      let options = {
        maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1920,  // (default: undefined)
        useWebWorker: true,      // (default: true)
      };
      try {
        const compressedFile = await imageCompression(file, options);
        this.imagePreview = URL.createObjectURL(compressedFile);
        this.$emit('change', compressedFile); // Emit the compressed file instead of the original file
      } catch (error) {
        console.log(error);
      }
    },
    removeFile() {
      this.imagePreview = null;
      this.$emit('change', null);
    },
    uploadFile() {
      this.$emit('upload', this.imagePreview);
      this.imagePreview = null;
    }
  },
  created() {
    if (this.base64Image) {
      // Convert base64 to Blob
      let byteCharacters = atob(this.base64Image);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], {type: "image/png"});
      // Create URL representing the image
      this.imagePreview = URL.createObjectURL(blob);
    }
  }
};
</script>
<style></style>
