<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index" @click="handleRowClick(item)" :class="getRowClass(onRowClick)">
        <slot name="custom-row" v-bind:row="item">
          <template v-for="(column, index) in columns">
            <td :key="index" v-if="hasValue(item, column)">
              <template v-if=" column === 'Image'">
                <img :src="'data:image/webp;base64,' + itemValue(item, column)" alt="" width="50" />
              </template>
              <template v-else>
                {{ itemValue(item, column) }}
              </template>
            </td>
          </template>
          <td v-if="userRole === 'Admin' && onDelete">
            <button type="button" class="btn btn-danger" @click.stop="handleDelete(item)" v-show="!isMobile">Delete</button>
            <button type="button" class="btn-sm btn-danger tim-icons icon-trash-simple" @click.stop="handleDelete(item)" v-show="isMobile"></button>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "base-table",
  data() {
    return {
      isMobile: window.innerWidth <= 768,
    };
  },
  created() {
    window.addEventListener('resize', this.updateIsMobile);
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateIsMobile);
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    onRowClick: {
      type: Function,
      default: null,
    },
    onDelete: {
      type: Function,
      default: null,
    },
    userRole: {
      type: String,
      default: '',
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    handleRowClick(item) {
      if (this.onRowClick !== null) {
        this.onRowClick(item);
      }
    },
    getRowClass(onRowClick) {
      return onRowClick ? 'clickable-row' : 'default-row';
    },
    handleDelete(item) {
      if (this.onDelete) {
        this.onDelete(item);
      }
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
};
</script>
<style scoped>
table tbody tr:hover {
  background-color: #5b5b6a; /* Change this to the color you want */
}
.clickable-row {
  cursor: pointer;
}
</style>
