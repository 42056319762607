import VueRouter from 'vue-router';
import routes from './routes';
import authService from '../services/authService';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await authService.checkAuth();  // Check authentication status
  const userRole = authService.getUserRole();  // Get the user's role

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else if (to.path === '/login' && isAuthenticated) {
    const redirect = to.query.redirect || '/mytools';
    next({ path: redirect });  // Redirect authenticated users to /dashboard or the previous page they were on
  } else if (to.matched.some(record => record.meta.roles && !record.meta.roles.includes(userRole))) {
    next({ path: '/not-authorized' });  // Redirect to a 'not authorized' page if the user's role is not allowed
  } else {
    next();
  }
});


export default router;
