// authService.js
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import config from '../config'

export default {
  data: {
    isAuthenticated: false,
    userRole: null  // Add a new data property for the user's role
  },
  login() {
    this.data.isAuthenticated = true;
  },
  logout() {
    this.data.isAuthenticated = false;
    this.data.userRole = null;  // Reset the user's role when they log out
  },
  async checkAuth() {
    return new Promise(async (resolve) => {
      const token = Cookies.get('accessToken');
      if (token) {
        try {
          await axios.get(config.apiUrl + '/locationType', { headers: { Authorization: `Bearer ${token}` } });
          const decodedToken = jwtDecode(token);
          this.data.userRole = decodedToken.role;  // Set the user's role from the decoded token
          this.login();
          resolve(true);
        } catch (error) {
          this.logout();
          resolve(false);
        }
      } else {
        this.logout();
        resolve(false);
      }
    });
  },
  getUserRole() {  // Add a new method to get the user's role
    return this.data.userRole;
  }
};
