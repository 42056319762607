<template>
    <div class="spinner"></div>
  </template>
  
  <script>
  export default {
    name: 'Spinner'
  }
  </script>
  
  <style scoped>
  .spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 0.5s linear infinite;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  